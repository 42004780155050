import React, { useEffect, useState } from "react";
import { Button, Input, Spinner } from "@nextui-org/react";
import { useAuth } from "context/AuthContext";
import { enable2faApi, generateAuthenticatorAppQRCodeApi } from "network/api/auth";
import { ApiResType } from "types/enum";
import { StepsType } from ".";
import { toast } from "sonner";
import { Link } from "react-router-dom";

export default function Setup({ setStep }: { setStep: (s: StepsType) => void }) {
  const { token } = useAuth();
  const [qrCode, setQrCode] = useState<string | null>(null);
  const [generating, setGenerating] = useState(true);
  const [code, setCode] = useState<string>("");
  const [saving, setSaving] = useState(false);

  useEffect(() => {
    if (!token) return;

    (async () => {
      try {
        setGenerating(true);
        const res = await generateAuthenticatorAppQRCodeApi({ token });

        if (res.type === ApiResType.SUCCESS) {
          if (res?.qr_code) {
            setQrCode("data:image/png;base64," + res?.qr_code);
          }
        }
      } catch (error) {
        console.log(error);
      } finally {
        setGenerating(false);
      }
    })();
  }, [token]);

  async function onSave() {
    if (!token) return;
    if (!code) {
      toast.error("Please enter the code from your authenticator app");
      return;
    }

    try {
      setSaving(true);
      const res = await enable2faApi({ token, code, enable: "1", type: "authenticator_app" });
      if (res.type === ApiResType.SUCCESS) {
        setStep("recovery");
      }

      if (res.type === ApiResType.ERROR) {
        toast.error(res.message || "Something went wrong, please try again later");
      }
    } catch (error) {
      console.log(error);
      toast.error("Something went wrong, please try again later");
    } finally {
      setSaving(false);
    }
  }

  return (
    <div className="max-w-screen-lg my-10">
      <div className="mb-5">
        <div className="text-2xl font-bold text-center mb-1">Setup two factor authentication (2FA)</div>
        <p className="text-sm max-w-screen-md mx-auto text-foreground-500 text-center">
          To secure your account, we recommend setting up two-factor authentication (2FA). This adds an extra layer of security to your account by requiring both your password and a verification code sent to your email or phone number.
        </p>
      </div>

      <div className="w-full space-y-7 border border-foreground-300/60 h-fit p-6 rounded-xl shadow-sm">
        <div className="flex flex-col gap-2">
          <div className="text-lg font-semibold">Setup authenticator app</div>
          <div className="text-sm text-foreground-500">
            Authenticator apps like Google Authenticator, Authy, Microsoft Authenticator, etc. generate one-time passwords that are used as a second factor to verify your identity when prompted during sign-in.
          </div>
        </div>

        <div>
          <div className="text-lg font-semibold">Scan this QR code with your authenticator app</div>
          <div className="h-40 mt-2 aspect-square border border-foreground-300/60 rounded-xl flex justify-center items-center overflow-hidden">
            {generating ? (
              <div className="flex justify-center items-center h-full">
                <Spinner />
              </div>
            ) : (
              qrCode && <img src={qrCode} alt="QR code" className="w-full h-full object-cover" />
            )}
          </div>
        </div>

        <div>
          <div className="text-lg font-semibold">Enter the code from your authenticator app</div>
          <div className="max-w-[200px]">
            <Input placeholder="XXX-XXX" maxLength={7} value={code || ""} onChange={(e) => setCode(e.target.value)} className="mt-2" />
          </div>
        </div>
        <div className="border-t border-foreground-300/60 pt-5 flex justify-center gap-2">
          <Button isDisabled={!code || !qrCode || saving} color="primary" onClick={onSave}>
            {saving ? (
              <div className="flex items-center gap-2">
                <Spinner color="white" size="sm" />
                <span>Saving...</span>
              </div>
            ) : (
              "Next"
            )}
          </Button>
          <Button variant="ghost" isDisabled={saving} as={Link} to={"/profile"}>
            Cancel
          </Button>
        </div>
      </div>
    </div>
  );
}
