import { useState, useCallback, memo } from "react";
import { Input } from "@mui/joy";
import { motion } from "framer-motion";
import { toast } from "sonner";
import { useSearchParams } from "react-router-dom";
import { useAuth } from "context/AuthContext";
import { verify2faApi } from "network/api/auth";
import { ApiResType } from "types/enum";
import { Button } from "@nextui-org/react";

export default function Verify2FA() {
  const [sParams] = useSearchParams();
  const token = sParams.get("token");
  const { onLoginSuccess } = useAuth();

  const [code, setCode] = useState<string[]>(Array(6).fill(""));
  const [verifying, setVerifying] = useState(false);

  const handleChange = useCallback((index: number, value: string) => {
    if (value.length > 1) {
      const cleanedValue = value.replace(/[^a-zA-Z0-9]/g, "").slice(0, 6);
      const newCode = Array(6)
        .fill("")
        .map((_, i) => cleanedValue[i] || "");
      setCode(newCode);
      return;
    }

    setCode((prev) => {
      const newCode = [...prev];
      newCode[index] = value.toUpperCase();
      return newCode;
    });

    if (value && index < 5) {
      requestAnimationFrame(() => {
        const nextInput = document.querySelector(`input[name="code-${index + 1}"]`);
        (nextInput as HTMLElement)?.focus();
      });
    }
  }, []);

  const handleKeyDown = useCallback(
    (index: number, e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Backspace" && !code[index] && index > 0) {
        requestAnimationFrame(() => {
          const prevInput = document.querySelector(`input[name="code-${index - 1}"]`);
          (prevInput as HTMLElement)?.focus();
        });
      }
    },
    [code]
  );

  const handlePaste = useCallback((e: React.ClipboardEvent) => {
    e.preventDefault();
    const pastedData = e.clipboardData.getData("text");
    const cleanedValue = pastedData.replace(/[^a-zA-Z0-9]/g, "").slice(0, 6);
    const newCode = Array(6)
      .fill("")
      .map((_, i) => cleanedValue[i] || "");

    setCode(newCode);

    const nextEmptyIndex = newCode.findIndex((val) => val === "");
    if (nextEmptyIndex !== -1 && nextEmptyIndex < 6) {
      requestAnimationFrame(() => {
        const nextInput = document.querySelector(`input[name="code-${nextEmptyIndex}"]`);
        (nextInput as HTMLElement)?.focus();
      });
    }
  }, []);

  const handleSubmit = useCallback(
    async (e: React.FormEvent) => {
      e.preventDefault();
      const verificationCode = code.join("");

      if (verificationCode.length !== 6) {
        toast.error("Please enter a complete 6-digit code");
        return;
      }

      if (!token) {
        toast.error("Invalid token");
        return;
      }

      try {
        setVerifying(true);
        const res = await verify2faApi({ token, code: verificationCode });

        if (res.type === ApiResType.SUCCESS) {
          onLoginSuccess({ data: res.data, persist: true });
        }
      } catch (error) {
        console.error(error);
      } finally {
        setVerifying(false);
      }
    },
    [code, token, onLoginSuccess]
  );

  return (
    <motion.div initial={{ opacity: 0, y: 20 }} animate={{ opacity: 1, y: 0 }} className="min-h-screen flex items-center justify-center bg-gray-50">
      <div className="max-w-md w-full p-6 bg-white rounded-xl shadow-lg">
        <h1 className="text-2xl font-bold text-center mb-6">Two-Factor Authentication</h1>
        <p className="text-gray-600 text-center mb-8">Enter the 6-digit code from your authenticator app</p>

        <form onSubmit={handleSubmit}>
          <div className="flex gap-2 justify-center mb-8">
            {code.map((digit, index) => (
              <CodeInput key={index} index={index} value={digit} onChange={handleChange} onKeyDown={handleKeyDown} onPaste={handlePaste} />
            ))}
          </div>

          <Button type="submit" fullWidth color="primary" isLoading={verifying}>
            Verify
          </Button>
        </form>
      </div>
    </motion.div>
  );
}

type CodeInputProps = {
  index: number;
  value: string;
  onChange: (index: number, value: string) => void;
  onKeyDown: (index: number, e: React.KeyboardEvent<HTMLInputElement>) => void;
  onPaste: (e: React.ClipboardEvent) => void;
};

const CodeInput = memo(({ index, value, onChange, onKeyDown, onPaste }: CodeInputProps) => (
  <Input
    name={`code-${index}`}
    value={value}
    onChange={(e) => onChange(index, e.target.value)}
    onKeyDown={(e) => onKeyDown(index, e)}
    onPaste={onPaste}
    className="w-12 h-12 text-center text-lg uppercase"
    slotProps={{
      input: {
        maxLength: 1,
        pattern: "[0-9A-Za-z]",
        style: { textTransform: "uppercase", textAlign: "center" },
      },
    }}
  />
));
CodeInput.displayName = "CodeInput";
