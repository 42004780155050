import Setup from "./Setup";
import Recovery from "./Recovery";
import { useState } from "react";

export type StepsType = "setup" | "recovery";

export default function Setup2FA() {
  const [step, setStep] = useState<StepsType>("setup");

  return <div className="max-w-screen min-h-screen flex justify-center ">{step === "setup" ? <Setup setStep={(s: StepsType) => setStep(s)} /> : <Recovery setStep={(s: StepsType) => setStep(s)} />}</div>;
}
