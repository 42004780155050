import Cookies from "js-cookie";
import { getUserDetailsApi } from "network/api/app";
import React, { Dispatch, SetStateAction, createContext, useContext, useEffect, useState } from "react";
import { getConnectedDiscordAccountApi, logoutApi } from "network/api/auth";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import { LoginApiResD } from "types/apiResponse";
import { UserProfileD } from "types/user";
import { STORAGE_KEYS } from "utils/constants/app";
import { DiscordAccountD } from "types/auth";

declare const window: any;

type AuthContextTypeD = {
  token: string;
  user: any;
  onLoginSuccess: (d: { data: LoginApiResD & UserProfileD; persist: boolean }) => any;
  logout: () => void;
  metaInfo: any;
  clearMetaInfo: () => void;
  onSignup: (d: { email: string }) => void;
  setUser: Dispatch<SetStateAction<any>>;
};

const AuthContext = createContext<AuthContextTypeD>({} as AuthContextTypeD);

export function useAuth() {
  return useContext(AuthContext);
}

export default function AuthProvider({ children }: { children: React.ReactNode }) {
  const [token, setToken] = useState("");
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const [user, setUser] = useState<any>({} as any);
  const [userId, setUserId] = useState("");
  const [metaInfo, setMetaInfo] = useState<any>(null);
  const [params] = useSearchParams();
  const [loadingProfile, setLoadingProfile] = useState(false);

  const redirectLink = params.get("redirect");
  const continueUrl = params.get("continue");
  const RedirectURL = params.get("redirect_url");

  const prodRedirect = Cookies.get(STORAGE_KEYS.prodURL);

  const onSignup = ({ email }: { email: string }) => {
    if (window.tolt_referral && email) {
      window.tolt.signup(email);
    }
  };

  // FUNCTIONS
  const onLoginSuccess = ({ data, persist = true }: { persist: boolean; data: LoginApiResD & UserProfileD }) => {
    if (data["validate_2fa"]) {
      navigate(`/verify-2fa?token=${data.token}`);
      return;
    }

    setUser(data);
    setToken(data.token);

    Cookies.set(STORAGE_KEYS.token, data.token, {
      domain: "yourgpt.ai",
      expires: 365,
    });
    Cookies.set(STORAGE_KEYS.userId, data.id, {
      domain: "yourgpt.ai",
      expires: 365,
    });
    setUserId(data.id);

    if (RedirectURL) {
      navigate(RedirectURL);
      return;
    }

    if (prodRedirect) {
      Cookies.remove(STORAGE_KEYS.prodURL, { domain: "yourgpt.ai" });
      window.location.href = prodRedirect;
      return;
    } else if (redirectLink) {
      Cookies.remove(STORAGE_KEYS.prodURL, { domain: "yourgpt.ai" });
      window.location.href = redirectLink;
      return;
    } else if (continueUrl) {
      window.location.href = continueUrl;
      return;
    } else {
      navigate("/products");
    }
  };

  const logout = async () => {
    setToken("");
    Cookies.remove(STORAGE_KEYS.token, { domain: "yourgpt.ai" });
    Cookies.remove(STORAGE_KEYS.userId, { domain: "yourgpt.ai" });
    setUserId("");

    if (typeof window !== "undefined") {
      window.postMessage({ action: "logoutFromExtension" }, "*");
    }

    navigate("/login");
    try {
      logoutApi({ token });
    } catch (err) {
      console.log("Err", err);
    }
  };

  const clearMetaInfo = () => {
    setMetaInfo(null);
  };
  // SIDE EFFECTS
  useEffect(() => {
    if (process.env.NODE_ENV === "development") {
      if (typeof process.env.REACT_APP_DUMMY_TOKEN !== "undefined" && process.env.REACT_APP_DUMMY_TOKEN) {
        setToken(process.env.REACT_APP_DUMMY_TOKEN);
        return;
      }
    }
    if (Cookies.get(STORAGE_KEYS.token)) {
      setToken(Cookies.get(STORAGE_KEYS.token) || "");
      setUserId(Cookies.get(STORAGE_KEYS.userId) || "");
      if (continueUrl) {
        window.location.href = continueUrl;
      }
    } else {
      const nonAuths = ["forgot-password", "login", "reset-password", "create-account", "verify-email", "process-login", "account-delete-request", "sso", "verify-2fa"];

      let isDash = !nonAuths.some((i) => pathname.includes(i));

      if (isDash) {
        navigate("/login");
      }
    }
  }, [navigate, pathname]);

  useEffect(() => {
    if (userId && typeof window !== "undefined") {
      window?.clarity("identify", userId);
      window?.clarity("set", "user", userId);
    }
  }, [userId]);

  /**
   * USER DETAIL
   */

  useEffect(() => {
    const getUserDetails = async () => {
      try {
        if (!token) {
          return;
        }
        setLoadingProfile(true);
        const res = await getUserDetailsApi({
          token,
        });
        setLoadingProfile(false);
        setUser(res?.data);
      } catch (err) {
        setLoadingProfile(false);
      }
    };
    if (token) {
      getUserDetails();
    }
  }, [token]);

  /*
  SEND TOKEN TO EXTENSION 
  */

  useEffect(() => {
    if (!token || typeof window === "undefined") return;
    console.log("Sending token to extension", token);
    window.postMessage({ action: "storeToken", token }, "*");
  }, [token]);

  return (
    <AuthContext.Provider
      value={{
        token,
        user,
        setUser,
        onLoginSuccess,
        logout,
        metaInfo,
        clearMetaInfo,
        onSignup,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
}
