import React, { useEffect, useState } from "react";
import { StepsType } from ".";
import { useAuth } from "context/AuthContext";
import { generateRecoveryCodeApi } from "network/api/auth";
import { ApiResType } from "types/enum";
import { Button } from "@nextui-org/react";
import { Link } from "react-router-dom";

export default function Recovery({ setStep }: { setStep: (s: StepsType) => void }) {
  const { token, setUser } = useAuth();
  const [recoveryCodes, setRecoveryCodes] = useState<string[]>([]);
  const [fetchingRecoveryCodes, setFetchingRecoveryCodes] = useState(false);

  useEffect(() => {
    if (!token) return;

    (async () => {
      try {
        setFetchingRecoveryCodes(true);
        const res = await generateRecoveryCodeApi({ token });
        if (res.type === ApiResType.SUCCESS) {
          setRecoveryCodes(res?.data || []);
        }
      } catch (error) {
        console.log(error);
      } finally {
        setFetchingRecoveryCodes(false);
      }
    })();
  }, [token]);

  const downloadRecoveryCodes = () => {
    const blob = new Blob([recoveryCodes.join("\n")], { type: "text/plain" });
    const url = URL.createObjectURL(blob);
    const a = document.createElement("a");
    a.href = url;
    a.download = "recovery_codes.txt";
    a.click();
  };

  return (
    <div className="max-w-screen-lg my-10">
      <div className="mb-5">
        <div className="text-2xl font-bold text-center mb-1">Recovery codes</div>
        <p className="text-sm max-w-screen-md mx-auto text-foreground-500 text-center">If you lose access to your authenticator app, you can use these recovery codes to regain access to your account.</p>
      </div>

      <div className="border border-foreground-300/60 h-fit p-6 rounded-xl shadow-sm">
        <div className="columns-1 sm:columns-2 mb-5">
          {recoveryCodes.map((code) => (
            <div key={code} className="flex items-center gap-2 text-2xl">
              <div className=" text-foreground-500">{code}</div>
            </div>
          ))}
        </div>

        <div className="flex justify-end">
          <Button size="sm" color="primary" variant="shadow" onClick={downloadRecoveryCodes}>
            Download recovery codes
          </Button>
        </div>

        <div className="border-t mt-5 border-foreground-300/60 pt-5 gap-2">
          <Button
            onClick={() => {
              setUser((prev: any) => ({ ...prev, "2fa_enabled": "1" }));
            }}
            as={Link}
            to={"/profile"}
          >
            Complete
          </Button>
        </div>
      </div>
    </div>
  );
}
