import { Alert, Button, Checkbox, CircularProgress, FormControl, FormLabel, Input, Sheet, Typography } from "@mui/joy";
import { loginApi, resendEmailVerificationApi } from "network/api/auth";
import { useState } from "react";
import { Link, useSearchParams } from "react-router-dom";
import SocialLoginBtns from "components/SocialLoginBtns";
import { useAuth } from "context/AuthContext";
import AuthLayout from "components/layouts/AuthLayout";
import { Helmet } from "react-helmet";
import { logErr } from "utils/helpers";
import { ApiResType } from "types/enum";
import { STORAGE_KEYS } from "utils/constants/app";

export default function Login() {
  const [loading, setLoading] = useState(false);
  const [searchParams] = useSearchParams();
  const showVerify = searchParams.get("verify");
  const [error, setError] = useState("");
  const { onLoginSuccess } = useAuth();
  const [email, setEmail] = useState("");
  const [verifyEmail, setVerifyEmail] = useState("");
  const [showVerifyError, setShowVerifyError] = useState(false);
  const [mailSent, setMailSent] = useState("");
  const [mailSentLoading, setMailSentLoading] = useState(false);

  const onSubmit = async (event: any) => {
    event.preventDefault();
    setError("");
    setMailSent("");
    setShowVerifyError(false);
    const formElements = event.currentTarget.elements;
    const data = {
      email: formElements.email.value,
      password: formElements.password.value,
      persistent: formElements.persistent.checked,
    };

    try {
      setLoading(true);
      const res = await loginApi({
        email: data.email,
        password: data.password,
      });

      if (res.type === "RXSUCCESS") {
        if (res?.data?.email_verified) {
          localStorage.removeItem(STORAGE_KEYS.email);
          onLoginSuccess({ data: res.data, persist: true });
        } else {
          setVerifyEmail(res?.data?.email);
          setShowVerifyError(true);
        }
      }
      setLoading(false);

      if (res.type === "RXERROR") {
        setError(res.message);
      }
    } catch (err: any) {
      logErr("Err", err);
      setLoading(false);
    }
  };

  const resendEmail = async () => {
    try {
      setMailSentLoading(true);
      setMailSent("");
      setShowVerifyError(false);
      const res = await resendEmailVerificationApi({
        email: verifyEmail || localStorage.getItem(STORAGE_KEYS.email)!,
      });
      if (res.type === ApiResType.SUCCESS) {
        setMailSent("A new email has been resent to your registered email address. Kindly check your inbox or spam/junk folder to locate the email and proceed with the verification process.");
      }
      setMailSentLoading(false);
    } catch (err) {
      setMailSentLoading(false);
      console.error("err", err);
    }
  };

  return (
    <AuthLayout>
      <Helmet>
        <title>Login - YourGPT</title>
        <meta
          name="description"
          content="Login to YourGPT and access your account. Our secure login process ensures your information is safe and protected. Easily manage your account, view your order history, and personalize your experience. Don't have an account? Sign up today and enjoy exclusive benefits!"
        />
      </Helmet>
      <div className="w-[90%] max-w-[400px]">
        <Typography fontWeight="xl" level="h4">
          Welcome back
        </Typography>
        <Typography
          sx={{
            mb: 2,
            color: "text.secondary",
          }}
        >
          Let's get started! Please enter your details.
        </Typography>

        <form className="flex flex-col gap-3" onSubmit={onSubmit}>
          <FormControl required>
            <FormLabel>Email</FormLabel>
            <Input placeholder="Enter your email" type="email" name="email" value={email} onChange={(e) => setEmail(e.target.value)} />
          </FormControl>
          <FormControl required>
            <FormLabel>Password</FormLabel>
            <Input placeholder="•••••••" type="password" name="password" />
          </FormControl>
          <Sheet
            sx={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
            }}
          >
            <Checkbox size="sm" label="Remember for 30 days" name="persistent" defaultChecked />

            <Link to={`/forgot-password${searchParams.get("continue") ? `?continue=${searchParams.get("continue")}` : ""}`} className="text-sm">
              Forgot password?
            </Link>
          </Sheet>

          {error && (
            <Alert color="danger" variant="soft">
              {error}
            </Alert>
          )}

          {(verifyEmail || showVerify) && (
            <>
              {mailSent ? (
                <Alert
                  color="primary"
                  sx={{
                    maxWidth: 400,
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  {mailSent}
                </Alert>
              ) : showVerifyError ? (
                <Alert
                  color="danger"
                  sx={{
                    maxWidth: 400,
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  Email not verified. Please check your email to verify.
                </Alert>
              ) : showVerify ? (
                <Alert
                  color="primary"
                  sx={{
                    maxWidth: 400,
                    textAlign: "left",
                    display: "flex",
                    justifyContent: "center",
                  }}
                >
                  You're almost there! An email has been sent to your registered address. Kindly verify it to complete the process. Remember to check both your inbox and spam/junk folder to ensure its arrival.
                </Alert>
              ) : (
                <></>
              )}
              <div className="ml-auto opacity-70 hover:opacity-100 duration-100 cursor-pointer flex items-center gap-2" onClick={resendEmail}>
                {mailSentLoading && <CircularProgress size="sm" />} Resend email
              </div>
            </>
          )}

          <Button type="submit" fullWidth loading={loading}>
            Sign in
          </Button>
        </form>

        <div className="py-2 text-center">
          <Typography level="body2" fontWeight={"md"}>
            Or
          </Typography>
        </div>

        <div className="">
          <SocialLoginBtns />
        </div>

        <div className="flex justify-center py-4">
          <Typography fontWeight={"md"}>
            Don't have an account?{" "}
            <Link to={`/create-account${searchParams.get("continue") ? `?redirect=${searchParams.get("continue")}` : ""}`} className="text-blue-600">
              Create new account
            </Link>
          </Typography>
        </div>
      </div>
    </AuthLayout>
  );
}
